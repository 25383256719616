
import 'regenerator-runtime/runtime';
import iJsBridge from "./ijsBridge";
import { Toast, Indicator } from 'mint-ui';
let h5Gps = null
let SMAmapGps = null
let AmapGps = null
async function getLocationPro(option) {
    return new Promise(async(resolve, reject) => {
        const locationMethods = [
            AMapGetLocation,
            ...(isWeChatEnvironment() ? [] : [SMAmapGetLocation]), 
            h5Location, 
        ];
        Indicator.open({
            text: '定位中...',
            spinnerType: 'fading-circle'
          });
        // 尝试所有方法直到成功或全部失败
        const tryAllMethods = async () => {
            let position
            for (const method of locationMethods) {
                try {
                    position = await method();
                    Indicator.close()
                    console.log('=-==输出定位-=-=-',position);
                    resolve(position);
                    return;
                } catch (e) {
                    Indicator.close()
                    console.log('==e-=',e);
                    reject(new Error('获取位置信息失败'))
                }
            }
            console.log(h5Gps,SMAmapGps,AmapGps);
            // try {
            //     let squareNl = Math.pow((h5Gps.nl*100000 - SMAmapGps.nl*100000), 2)
            //     let squareEl = Math.pow((h5Gps.el*100000 - SMAmapGps.el*100000), 2)
            //     console.log('------h5和原生经纬度误差------'+(squareNl + squareEl) ** 0.5+'米')
                
            // } catch (e) {
            //     console.log('------h5和原生经纬度计算失败------')
            // }
            // try {
            //     let squareNl = Math.pow((h5Gps.nl*100000 - AmapGps.nl*100000), 2)
            //     let squareEl = Math.pow((h5Gps.el*100000 - AmapGps.el*100000), 2)
            //     console.log('------h5和高德经纬度误差------'+(squareNl + squareEl) ** 0.5+'米')
                
            // } catch (e) {
            //     console.log('------h5和高德经纬度计算失败------')
            // }
            // try {
            //     let squareNl = Math.pow((SMAmapGps.nl*100000 - AmapGps.nl*100000), 2)
            //     let squareEl = Math.pow((SMAmapGps.el*100000 - AmapGps.el*100000), 2)
            //     console.log('------原生和高德经纬度误差------'+(squareNl + squareEl) ** 0.5+'米')
                
            // } catch (e) {
            //     console.log('------原生和高德经纬度计算失败------')
            // }
            Indicator.close()
        };
        tryAllMethods().catch(new Error('所有位置获取方法均失败'));
    });
}
function isWeChatEnvironment() {
    return /MicroMessenger/i.test(navigator.userAgent);
}
function h5Location() {
    return new Promise((resolve, reject) => {
        console.log('=-==进行h5定位-=-=-');
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position && position.coords) {
                        let positionInfo = {
                            nl: position.coords.latitude,
                            el: position.coords.longitude
                        }
                        console.log('=-=h5Location-=-=-',positionInfo);
                        h5Gps = positionInfo
                        resolve(positionInfo)
                    } else {
                        console.log('=-=h5Location获取失败-=-=-');
                        reject(new Error('获取位置信息失败'))
                    }
                    console.log('=-==结束h5定位-=-=-');
                },
                (error) => {
                    console.log('=-=h5Location获取失败-=-=---');
                    reject(new Error('获取位置信息失败'))
                },
                options
            );
        } else {
            console.log('=-=浏览器不支持地理位置服务-=-=-');
            reject(new Error('浏览器不支持地理位置服务'))
        }
    })
}
function SMAmapGetLocation() {
    return new Promise((resolve, reject) => {
        console.log('=-==进行原生定位-=-=-');
        var options = {
            method: "SMAmap.getLocation",
            postData: {
                loadUrl: window.location.href,
            },
            callback: (viewData) => {
                console.log('=-=SMAmapGetLocation返回-=-=-',viewData);
                let positionInfo = {}
                try {
                    positionInfo = {
                        nl: viewData.lat,
                        el: viewData.lon
                    }
                    SMAmapGps = positionInfo
                    console.log('=-=SMAmapGetLocation-=-=-',positionInfo);
                    resolve(positionInfo)
                } catch (e) {
                    console.log('=-=原生获取失败-=-=-');
                    reject(e);
                }
                console.log('=-==结束原生定位-=-=-');
            }
        };
        const IJsBridge = new iJsBridge({ alert: ()=>{} });
        IJsBridge.call(options);
    })
}

function AMapGetLocation() {
    return new Promise((resolve, reject) => {
        console.log('=-==进行高德定位-=-=-');
        AMap.plugin("AMap.Geolocation", function() {
            try {
                const geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true,
                    timeout: 3000,
                });
                geolocation.getCurrentPosition((status, result) => {
                    if (status === "complete" && result && result.position) {
                        console.log('=-=-AMapGetLocation=-=-',{
                            nl: result.position.lat,
                            el: result.position.lng,
                        });
                        AmapGps = {
                            nl: result.position.lat,
                            el: result.position.lng,
                        }
                        resolve({
                            nl: result.position.lat,
                            el: result.position.lng,
                        });
                    } else {
                        let errorMessage = '位置获取失败';
                        if (result && result.message) {
                            errorMessage += `: ${result.message}`;
                        }
                        console.log('=-=位置获取失败-=-=-');
                        reject(new Error(errorMessage));
                    }
                    console.log('=-==结束高德定位-=-=-');
                });
            } catch (error) {
                console.log('=-=高德调用失败-=-=-');
                reject(error);
            }
        }, reject);
    });
}

export default getLocationPro

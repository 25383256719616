
import item5Detail from "@/components/OrderList/Item5Detail2.js";
import { Dialog } from 'vant';
import { miceService } from "@/service/miceService.js";
import { Indicator } from 'mint-ui';
import { SmartStorage } from "smart-core-util";
// import iJsBridge from "smart-javascript-bridge";
import { Toast } from 'mint-ui';
import getLocationPro from "@/utils/getLocation.js";
import iJsBridge from "./ijsBridge";
export default {
  mixins: [item5Detail],
  methods: {
    checkAmapAuthStatus(){
      return new Promise((resolve, reject) => {
        var options = {
          method: "SMAmap.checkAmapAuthStatus",
          postData: {
              loadUrl: window.location.href,
          },
            callback: (viewData) => {
              console.log('=-=原生查询定位权限-=-',viewData);
              resolve(viewData)
            }
        };
        const IJsBridge =  new iJsBridge({alert: Toast});
        IJsBridge.call(options);
      })
    },
    async getLocationFailed(onlyGetLocation) {
      return new Promise(async(resolve, reject) => {
        let checkAmapAuth = {}
        if(process.env.VUE_APP_DEPLOY == "weChat" || process.env.VUE_APP_DEPLOY == "offline"){
          checkAmapAuth.code = 0
        }else{
          checkAmapAuth = await this.checkAmapAuthStatus()
        }
        getLocationPro().then(async(option)=>{
            this.imygeoLocation = {
              nl: option.nl,
              el: option.el,
            };
            if(onlyGetLocation){// 仅仅获取定位经纬度
              resolve(option)
            }else{
              await this.checkDistance()
              resolve(option)
            }
        }).catch((error)=>{
          if(this.mealRules.electricFence && checkAmapAuth.code == '0') {
            // electricFence:电子围栏开关 checkAmapAuth.code:{1:设备定位权限开启，0：设备定位权限关闭}
            let msg = "请检查移动设备定位是否开启且允许当前APP获取位置信息同时网络是否正常，如未开启或允许请设置后再次操作结账，点击“确定”后会回到用餐列表"
            if(this.event.tenant_code == "roche"){
              msg = '因支付系统升级，请在支付前打开并允许设备获取您的GPS定位，设置后再次操作结账\niPad设备，可在【设置 - 隐私与安全性 - 定位服务】中打开定位权限\n其他移动端设备，可在【设置 - 应用和服务】中打开定位权限'
            }
            Dialog.confirm({
              title: "提示",
              message: msg,
              confirmButtonText: "确定",
              showCancelButton: false,
              closeOnClickOverlay: false
            }).then(async action => {
              if (action == "confirm") {
                this.$router.push({ path: "/orderList" });
                reject(error)
              }
            });
          }else{
            console.log('--====获取定位失败====--');
            reject(error)
          }
        })
      })
    },
    async checkDistance() {
      return new Promise(async (resolve, reject) => {
        //定位地点
        let m1 = new AMap.Marker({
          position: new AMap.LngLat(
            this.imygeoLocation.el,
            this.imygeoLocation.nl
          )
        });
        //餐厅地点
        let orderGPS = this.orderDetail.itsItemData.gps || JSON.parse(this.orderDetail.itsItemData.gps);
        let el = Number(orderGPS.el) || 0;
        let nl = Number(orderGPS.nl) || 0;
        let m2 = new AMap.Marker({
          position: new AMap.LngLat(el, nl)
        });
        var p1 = m1.getPosition();
        var p2 = m2.getPosition();
        var distance = Math.round(p1.distance(p2));
        console.log("distance===", distance);
        this.goPay = false;
        //超过范围后存储当前定位信息
        let queryParams = {
          NodeCode: "SubmitGpsFail",
          ContextDataKeys: {
            ActionStart: "ActionStart",
            ItemId: this.orderDetail.itemId,
            EL: this.imygeoLocation.el,
            NL: this.imygeoLocation.nl,
            Distance: distance
          }
        }
        await miceService.locationFailed(queryParams)
        if (Number(distance) > (this.mealRules.electricFenceDistance || 500) && this.mealRules.electricFence && this.orderDetail.sourceCode != "MeiTuan") {
          Dialog.confirm({
            title: "提示",
            message: `当前支付地点可能不在${this.orderDetail.itsItemData.shopName}餐厅附近，如您已更换餐厅用餐，请点击“餐厅更换”，更换餐厅后重新支付。`, //提示内容
            confirmButtonText: "餐厅更换",
            showCancelButton: false,
            closeOnClickOverlay: false
          }).then(async action => {
            if (action == "confirm") {
              this.changeShop(this.orderDetail);
            }
          });
        } else {
          resolve(true)
        }
      })
    },
  }
}